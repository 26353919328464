<template>
  <middleware-sidebar-outline
  :uuid="uuid"
  :middlewareID="middlewareID"
  :middlewareType="middlewareType"
  @close="$emit('close')"
  @shown="$emit('shown')"
  @saveMiddleware="buildAgents"
  v-model="variables"
>
  <div class="mt-1" v-if="postgreSqlInsertData.connection">

        <b-col cols="6">
          <connection-register-input class="my-50" :connectionTypeID="2" v-model="postgreSqlInsertData.connection"/>
        </b-col>

        <b-col v-for="pos in renderCustomInputList" :key="pos.label" :cols="pos.cols">
          <div>
            <custom-input
              v-if="postgreSqlInsertData[pos.label] != undefined && variables"
              label="Destiny"
              :id="`custom-input-${pos.label}`"
              class="my-50"
              :possibleValues="variables"
              v-model="postgreSqlInsertData[pos.label].register_1"
            >
              <template #label>
                <span class="text-capitalize">
                  {{ pos.label }} <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter/>
                </span>
              </template>
            </custom-input>
            <div v-else class="mb-2">
              <b-skeleton class="mb-25" width="25%" height="12px"/>
              <b-skeleton class="mb-25" width="100%" height="37px"/>
              <b-skeleton class="mb-25" width="25%" height="14px"/>
            </div>
          </div>
        </b-col>

        <b-col cols="12" class="mt-2">
          <label for="logic-expression-where">
            Values 
            <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter/>
          </label>
          <vue-perfect-scrollbar class="mt-50 border shadow rounded p-1">
            <key-value
              v-if="variables"
              :ref="getID('header')"
              :addButtonText="$t('+ add new value')"
              v-model="postgreSqlInsertData.values"
              dropZone
              :possibleValues="variables"
              @deleteItem="
                (payload) => {
                  deleteAgent(payload.item, payload.index, 'header');
                }
              "
              :defaultValue="defaultExpressionValue"
            />
          </vue-perfect-scrollbar>
        </b-col>
  </div>
</middleware-sidebar-outline>   
</template>

<script>
import { 
  BPopover,
  BIcon,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BContainer,
  BSidebar,
  BSpinner,
  BTabs,
  BTab,
  BCardText,
  BCardBody,
  BCard,
  BAvatar,
  BSkeleton,
  BFormCheckbox,
  BCollapse,
  BFormGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VariablesPanel from "../../VariablesPanel.vue";
import { makeToast } from '@/layouts/components/Popups'
import Source from '@/custom/class/Agent/Source'
import ActionsReorderList from '@/layouts/components/Transmission/Middleware/Agent/ActionsReorderList.vue'
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import HelperTooltip from '@/layouts/components/HelperTooltip'
import Sources from '@/custom/class/Enum/Sources.js'
import VSelect from 'vue-select'
import KeyValue from "@/views/pages/middleware/MiddlewareManagement/Components/Requester/KeyValueItems.vue";
import { VueSelect } from 'vue-select';

import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-markup.js';
// import 'prismjs/components/prism-xml-doc.js';
// import 'prismjs/components/prism-json.js';
import 'prismjs/components/prism-sql.js';
import 'prismjs/themes/prism-tomorrow.css';
import DefaultAgent from '@/layouts/components/Transmission/Middleware/Agent/DefaultAgent';

import MiddlewaresMixin from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewaresMixin.js'
import CertificateInput from '@/views/pages/middleware/MiddlewareManagement/Components/CertificateInput.vue';
import ConnectionRegisterInput from '@/views/pages/middleware/MiddlewareManagement/Components/ConnectionRegisterInput.vue'
import Middlewares from "@/custom/class/Enum/Middlewares.js"
import MiddlewareSidebarOutline from "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareSidebarOutline.vue"
import * as MiddlewareFunctions from  "@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/MiddlewareFunctions.js"


  export default {
    mixins: [MiddlewaresMixin],
    components: {
      BPopover,
      BSidebar,
      BIcon,
      BButton,
      BRow,
      BCol,
      BInputGroup,
      BInputGroupAppend,
      BFormInput,
      BContainer,
      VuePerfectScrollbar,
      VariablesPanel,
      BSpinner,
      BTabs,
      BTab,
      BCardText,
      BCard,
      BAvatar,
      BCardBody,
      ActionsReorderList,
      PrismEditor,
      CustomInput,
      BSkeleton,
      HelperTooltip,
      VSelect,
      KeyValue,
      BFormCheckbox,
      VueSelect,
      BCollapse,
      BFormGroup,
      BInputGroupPrepend,
      CertificateInput,
      ConnectionRegisterInput,
      MiddlewareSidebarOutline
    },
    props: {
      i18nKey: {
        type: String,
        default: "postgresql_insert"
      },
      middlewareID: {
        type: Number,
        required: true,
      },
      uuid: {
        type: String,
        required: true,
      },
      value: {
        type: Array,
        required: true,
      }
    },
    data() {
      return {
        uuidMap: {},
        variables: undefined,
        variant_map: undefined,

        agentList: [],
        isSaving: false,
        changingPosition: undefined,
        changerSelection: null,
        selectChangerOptions: undefined,
        middlewareType: new Middlewares().items.find(el=> el.id == 50),

        renderCustomInputList: [ // lista de items do array de data que são renderizados no DOM por um v-for
          // { label: 'username', cols: "6" },
          // { label: 'password', cols: "6" },
          // { label: 'database', cols: "4" },
          // { label: 'host', cols: "4" },
          { label: 'table', cols: "6" },
          // { label: 'port', cols: "4" },
        ],

        postgreSqlInsertData: {
          // username: '',
          // password: '',
          // database: '',
          // host: '',
          table: '',
          // port: '5432',
          // ssl: 'disabled',
          values: [],
          // certificate: '',
          where: '',
          connection: ''
        },

        fieldName: {
          username: 'USERNAME',
          password: 'PASSWORD',
          database: 'DATABASE',
          host:     'HOST',
          table:    'TABLE',
          where:    'WHERE',
          port:     'PORT',
          ssl:      'TLS',
          values:   'VALUE',
          certificate : 'CERTIFICATE',
          connection: 'CONNECTION'
        },

        requiredRegisters: {
          username: [ 'register_1' ],
          password: [ 'register_1' ],
          database: [ 'register_1' ],
          host: [ 'register_1' ],
          table: [ 'register_1' ],
          values: [ 'register_1', 'register_destiny' ],
          port: [ 'register_1' ],
          ssl: [ 'register_1' ],
          where: [ 'register_1' ],
          certificate: [ 'register_1' ],
          connection: [ 'register_1' ],
        },

        agentModifiers: {
        },

        definePayloadModifier: (payload) => {
          return {
            ...payload,
            register_destiny: {
              value: `VALUE.${payload.register_destiny.value}`,
              source: payload.register_destiny.source
            }
          }
        },


        // sslOptions: ["disabled", "require", "verify-ca", "verify-full"],

        changerOptions: [],

        defaultAgentActionID: 3,
        defaultAgentBlockID: 1,
      }
    },
    computed: {
      agents: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      sourceOptions() {
        return new Sources().items
      },
      defaultExpressionValue() {
        return DefaultAgent.setAgent(this, {
          id: null,
          enum_agent_action_id: this.defaultAgentActionID,
          enum_agent_block_id: this.defaultAgentBlockID,
          register_1: {
            source: 7,
          },
          register_2: {},
          register_destiny: {
            source: 21,
          },
          execution_order: 1,
          fatal_on_fail: true,
        })
      }
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        this.initializeAgents();
        // this.setRegisterSource();
      },
      highlighter(code) {
        return highlight(code, languages.sql); // languages.<insert language> to return html with markup
      },
      configureVars() {
        this.variant_map = this.$refs[this.getID("variables-panel")].getVariantMap();
        this.variables = this.$refs[this.getID("variables-panel")].getAllVars();
      },
      isObject(item) {
        return item instanceof Object && !(item instanceof Array);
      },
      isArray(item) {
        return item instanceof Array;
      },
      buildAgents() {
        this.isSaving = true;
        Object.keys(this.postgreSqlInsertData).forEach((key) => {
          if (this.postgreSqlInsertData[key] instanceof Array) {
            this.postgreSqlInsertData[key].forEach((value) => {
              if (this.verifyRequiredRegister(key, value)) {
                this.agentList.push(DefaultAgent.defineToAPI(this.definePayloadModifier(value), this.middlewareID));
              }
            })
          } else {
            if (this.agentModifiers[key]) {
              this.agentModifiers[key](this.postgreSqlInsertData[key])
            }
            if (this.verifyRequiredRegister(key)) {
              this.agentList.push(DefaultAgent.defineToAPI(this.postgreSqlInsertData[key], this.middlewareID));
            }
          }
        });
        this.saveAgents();
      },
      verifyRequiredRegister(key, value = this.postgreSqlInsertData[key]) {
        let state = true

        this.requiredRegisters[key].forEach((register) => {
          if (!value[register].source || !value[register].value) {
            state = false
            return;
          }
        })
        return state
      },
      saveAgents() {
        this.$store
          .dispatch("saveAgents", {
            agentList: this.agentList,
            transmissionID: this.$route.params.transmissionID,
          })
          .then((response) => {
            makeToast({
              title: this.$t("agent.toast.create_agents.success.title"),
              text: this.$t("agent.toast.create_agents.success.message"),
              variant: "success",
              icon: "CheckIcon",
            });
            this.$emit("saved", response.data);

          })
          .catch((error) => {
            this.isSaving = false;
          });
      },
      initializeAgents() {
        Object.keys(this.postgreSqlInsertData).forEach((pos) => {
          this.postgreSqlInsertData[pos] = MiddlewareFunctions.getValueFrom(
            {
              default: DefaultAgent.setAgent(this, {
                id: null,
                enum_agent_action_id: this.defaultAgentActionID,
                enum_agent_block_id: this.defaultAgentBlockID,
                register_1: {
                  value: this.postgreSqlInsertData[pos],
                  source: 7,
                },
                register_2: {},
                register_destiny: {
                  source: 21,
                  value: this.fieldName[pos],
                },
                execution_order: 1,
                fatal_on_fail: true,
              }),
              source: 21,
              value: this.fieldName[pos],
              from: {
                value: "register_destiny",
                source: "register_destiny",
              },
              modifier: false,
              multiple: false,
              expected: {
                source: "7",
                from: "register_1",
              },
            },
            this.agents
          );
        })

        this.postgreSqlInsertData.values = MiddlewareFunctions.getValueFrom(
          {
            default: [],
            source: 21,
            value: "VALUE.",
            from: {
              source: "register_destiny",
              value: "register_destiny",
            },
           
            data_modifier: (el) => {
              return {
                ...el,
                register_destiny: {
                  value: el.register_destiny.value.split('.')[1],
                  source: el.register_destiny.source,
                } 
              };
            },
            modifier: (el) => {
              return el.split('.')[0] + '.';
            },
            multiple: true,
          },
          this.agents
        );

      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
      sidebarShown() {
this.$emit("shown")
        this.isSaving = false;

        let el = document.getElementsByTagName("html");
        el[0].classList.add("hide-scrollbar");
      },
      sidebarHidden() {
        this.$emit("close");

        let el = document.getElementsByTagName("html");
        el[0].classList.remove("hide-scrollbar");
      },
      toggleSidebar() {
        this.$root.$emit("bv::toggle::collapse", this.uuid);
      },
    
    }

  }
</script>

<style lang="scss" scoped>

  .sidebar-container{
    max-height: 97vh !important;
    height: fit-content !important;
    overflow-y: auto !important;
  }
  
  .sidebar-fixed-header{
    position: sticky !important;
    top: 0 !important;
    z-index: 2;
  }
  
  .sidebar-content{
    position: relative !important;
    padding-bottom: 20px;
    margin-bottom: 70px !important;
  }
  
</style>

<style lang="scss" scoped>
.agent-text {
  display: block !important;
  width: 300px;
}

.my-editor {
  background: #161d31;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;

  pre, textarea {
    background: none !important;
    border: none !important;
    outline: none !important;

    &:focus, &:active, &:focus-visible {
      border: none !important;
      outline: none !important;
    }
  }
}

.height-500 {
  height: 500px;
}

</style>
<style lang="scss">
.bg-default,
.b-sidebar-header {
  background-color: #151925 !important;
}

.agent-sidebar {
  .b-sidebar-right {
    border-left: solid 1px #0d111c !important;
  }

  .b-sidebar-body {
    overflow: hidden !important;
  }

  .control-height {
    position: relative;
    overflow: auto;

    min-height: 100%;
    padding-bottom: 50px;
  }

  .limit-height {
    height: calc(100% - 215px) !important;
  }

  .saving-adjust-text-position {
    position: relative;
    top: 2px;
  }
}

</style>